import React from 'react'
import bem from 'bem'
import css from './Posts.module.scss'
import { default as Layout } from 'layouts/Main'
import Loop from 'components/Loop'
import SEO from 'components/SEO'
import usePageScrollTop from 'hooks/usePageScrollTop'

const b = bem.scenePosts(css)

const Posts = props => {
	usePageScrollTop()
	const { pageContext } = props
	const { number } = pageContext
	const title = `Публикации${number > 1 ? ` (часть ${number})` : ''}`
	return (
		<Layout className={b()} title={title} description="Делимся своим опытом. Кратко и информативно" isPadding>
			<SEO
				title={`${title} — Парсинг сайтов «Парсик»`}
				description="Делимся своим опытом в парсинге сайтов. Раскрываем самые важные и полезные темы для улучшения бизнеса"
			/>
			<Loop {...props} />
		</Layout>
	)
}

export default Posts
