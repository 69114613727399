import { useLayoutEffect } from 'react'

const usePageScrollTop = param => {
	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	}, [param])
	return null
}

export default usePageScrollTop
